import React from "react"
import { graphql } from "gatsby"
import Layout from "../pages/components/Layout"
import { Row, Col } from "react-bootstrap";
import ImageFigure from "../pages/components/ImageFigure";
// import Img from "gatsby-image"


export default function pageTemplate({ data }) {
  const post = data.markdownRemark

  return (
    <Layout 
    containerClass="bg-light bg-taieri"
    containerStyle={{backgroundSize: '2000%', backgroundPosition: '0% 100%'}}>
      <Row>
        <Col xl={12} className="align-center mt-5 pt-5 px-5">
            <h1 className="site-title text-lowercase mt-5 mb-3">{post.frontmatter.title}</h1>
            <p className="h5 px-5 py-3 font-weight-light">{post.frontmatter.text1}</p>
        </Col>
        <Col xl={12}>
            <ImageFigure
            className="d-flex mx-auto w-75 p-3 drop-shadow"
            src={post.frontmatter.img1}
            imgAlt={post.frontmatter.img1cap}
            imgCap={post.frontmatter.img1cap}
            />
        </Col>
    </Row>

    <Row>
        <Col xl={12}>
            <Row className="mt-4 mx-5 shadow-lg rounded-lg">
                <Col xl={6} className="align-left bg-light min-50 p-5">
                <h1 className="display-4 font-weight-bold">>_</h1>
                <h1 className="site-title">{post.frontmatter.subTitle}</h1>
                <p className="py-3">{post.frontmatter.text2}</p>
                </Col>
                <Col xl={6} className="align-center min-50 bg-black bg-taieri p-0">
                <ImageFigure
                className="w-100"
                src={post.frontmatter.img2}
                imgAlt={post.frontmatter.img2cap}
                imgCap={post.frontmatter.img2cap}
                />
                </Col>
            </Row>
        </Col>
    </Row>

    <Row>
        <Col xl={12}>
            <Row className="mt-5 mx-5 shadow-lg rounded-lg">
                <Col xl={6} className="align-center min-50 bg-black p-0">
                    <ImageFigure
                    className="w-100"
                    src={post.frontmatter.img3}
                    imgAlt={post.frontmatter.img3cap}
                    imgCap={post.frontmatter.img3cap}
                    />
                </Col>
                <Col xl={6} className="align-center bg-light min-50 bg-white p-0">
                    <ImageFigure
                    className="w-100"
                    src={post.frontmatter.img4}
                    imgAlt={post.frontmatter.img4cap}
                    imgCap={post.frontmatter.img4cap}
                    />
                </Col>
            </Row>
        </Col>
    </Row>

    <Row>
        <Col xl={12}>
            <Row className="m-5 shadow-lg rounded-lg">
                <Col xl={12} className="align-center min-50 bg-black p-0">
                    <ImageFigure
                    className="w-100"
                    src={post.frontmatter.img5}
                    imgAlt={post.frontmatter.img5cap}
                    imgCap={post.frontmatter.img5cap}
                    />
                </Col>
            </Row>
        </Col>
    </Row>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        text1
        subTitle
        text2
        img1
        img2
        img3
        img4
        img5
        img1cap
        img2cap
        img3cap
        img4cap
        img5cap
      }
    }
  }
`


  

               
