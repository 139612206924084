import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Menu from './Menu';
import Footer from './Footer';
// import Fade from 'react-awesome-reveal/Fade';
// import {Fade} from 'react-awesome-reveal';

export default function Layout(props) {

    useEffect(() => {
    // Array paaattern inn colors: orange, lightblue, blue, yellow, lightgrey, white

    const colors = ['#ffffff', 'darkorange', '#87C1D5', '#0091D1', '#FFD500', '#E5E5E5']; 

    // ff4a00 dark neon orange

    // Array miami colors 
    // const colors = ['#ffffff', '#019D97', '#FFDE59', '#642EC7', '#0091D1']; 
      
    // selecting random color 
    const random_color = colors[Math.floor(Math.random() * colors.length)]; 
      
    // select DOM element and add backgroundColor
    const x = document.querySelector('body'); 
    x.style.backgroundColor = random_color; 

  }, []);
    return (
        <>
            <Menu />
            <Container fluid 
            className={`${props.containerClass} p-0`}
            style={props.containerStyle}>
                {props.children}
            </Container>
            <Footer />
        </>
    )
}
