import React, { Component } from 'react';
import { Link } from "@reach/router";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Fade from 'react-reveal/Fade';

export class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {date: new Date()};
      }
    render() {         
        return (
            <Fade>
            <footer>
                <Container fluid className="p-0 relative">
                    <Row className="d-flex bg-black text-light p-0">
                        <Col xl={3} md={6} className="align-left-top px-5 pt-5 font-weight-bold">
                            <Fade top cascade>
                            <div className="display-4 font-weight-light text-light mb-0"
                            style={{marginTop: '-20px'}}>+</div>
                            <h3>Menu.</h3>
                            <div className="h3 m-0">―</div>
                            <ul className="list-unstyled blockquote">
                               <li><AniLink 
                                color="hotpink"
                                paintDrip 
                                to="/">
                                    Home
                                </AniLink></li> 
                                <li><AniLink 
                                cover
                                bg="#000" 
                                entryoffset={80}
                                direction="down"
                                to="/about">
                                    About
                                </AniLink></li>
                                <li><AniLink 
                                cover
                                bg="#000" 
                                entryoffset={80}
                                direction="right"
                                to="/services">
                                    Services
                                </AniLink></li>
                                <li><AniLink 
                                cover
                                bg="#000" 
                                entryoffset={80}
                                direction="top"
                                to="/">
                                    Projects
                                </AniLink></li>
                            </ul>
                            </Fade>
                        </Col>
                        <Col xl={3} md={6} className="align-left-top px-5 pt-5 font-weight-bold">
                            <Fade top cascade>
                            <div className="display-4 font-weight-light text-light mb-0"
                            style={{marginTop: '-20px'}}>+</div>
                            <h3>Work.</h3>
                            <div className="h3 m-0">―</div>
                            <ul className="list-unstyled blockquote">
                                {/* <Link to="/">
                                    <a><li>Warren Yen</li></a>
                                </Link>
                                <Link to="/">
                                    <a><li>Jaxx Liberty</li></a>
                                </Link>
                                <Link to="/">
                                    <a><li>Neil Whitford</li></a>
                                </Link>
                                <Link to="/">
                                    <a><li>Big Horn Sheep</li></a>
                                </Link> */}
                            </ul>
                            </Fade>
                        </Col>
                        <Col xl={3} md={6} className="align-left-top px-5 pt-5 font-weight-bold">
                            <Fade top cascade>
                            <div className="display-4 font-weight-light text-light mb-0"
                            style={{marginTop: '-20px'}}>+</div>
                            <h3>More.</h3>
                            <div className="h3 m-0">―</div>
                            <ul className="list-unstyled blockquote">
                                <a href="https://github.com/masonmedia" target="_blank" rel="noreferrer"><li>Github</li></a>
                                <a href="https://www.behance.net/andrewmasonmedia" target="_blank" rel="noreferrer"><li>Behance</li></a>
                                <a href="http://copy.andrewmasonmedia.com/" target="_blank" rel="noreferrer"><li>Writing/Editing</li></a>
                                {/* <Link to="/">
                                    <a><li>Resume</li></a>
                                </Link> */}
                            </ul>
                            </Fade>
                        </Col>
                        <Col xl={3} md={6} className="align-left-top px-5 pt-5 font-weight-bold">
                            <Fade top cascade>
                            <div className="display-4 font-weight-light text-light mb-0"
                            style={{marginTop: '-20px'}}>+</div>
                            <h3>Connect.</h3>
                            <div className="h3 m-0">―</div>
                            <ul className="list-unstyled blockquote">
                                <a href="https://www.linkedin.com/in/andrewmasonmedia/" target="_blank" rel="noreferrer"><li>Linkedin</li></a>
                                <a href="https://www.facebook.com/andrewmasonmedia/" target="blank"><li>Facebook</li></a>
                                <a href="https://twitter.com/C_AndrewMason" target="_blank" rel="noreferrer"><li>Twitter</li></a>
                                <a href="mailto:andrewmasonmedia@gmail.com"><li>Email</li></a>
                            </ul>
                            </Fade>
                        </Col>
                        <Col sm={12} className="align-center font-weight-bold pt-4 pb-5">
                        <p id="copyright" className="mb-0">
                            Mason Media | &copy; {this.state.date.getFullYear()}
                        </p>
                        </Col>
                    </Row>
                </Container>
            </footer>
            </Fade>
        )
    }
}

export default Footer;

